export const tableOption = {
  border: true,
  stripe: true,
  menuAlign: 'center',
  selection: true,
  tip: false,
  searchMenuSpan: 6,
  align: 'center',
  editBtn: false,
  delBtn: false,
  addBtn: false,

  column: [{
    fixed: true,
    label: '角色',
    prop: 'roleName',
    span: 24,
    rules: [{
      required: true,
      message: "请输入角色",
      trigger: "blur"
    }]
  },{
    fixed: true,
    label: '管理人员',
    prop: 'userList',
/*    type:'select',
    multiple:true,*/
    // dicData:[],
    span: 24,
    rules: [{
      required: true,
      message: "请选择管理员",
      trigger: "blur"
    }]
  }]


}
