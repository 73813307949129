const outOption = {
  title: "角色管理",
  column: [
    {
      label: '角色',
      prop: 'roleName',
    },
    {
      label: '管理人员',
      prop: 'userList',
    }
  ],
};
export default outOption
